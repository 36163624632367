import { useMemo } from "react";
import { useEditItem2, useNewItem } from "../../../api/useNewItem";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { IUserRole } from "../typings";

export interface APIToken {
  _id: string;
  email: string;
  roles: string[];
  created_datetime: string;
  is_blocked: boolean;
  token?: string;
}

interface Config {
  loadRoles?: boolean;
}

export const useAPITokensManagement = (apiPath: string, cfg?: Config) => {
  const data = useLoadedData<APIToken[]>(apiPath, []);

  const uiconfig = useLoadedData<Schema>(`${apiPath}/uiconfig`, {}, !!cfg?.loadRoles);

  const roles: IUserRole[] = useMemo(() => {
    return (uiconfig.data.roles?.values || []).filter(x => !!x).map(({ value, label }) => ({ key: value, name: label }));
  }, [uiconfig.data]);

  const createToken = useNewItem<Pick<APIToken, "roles">, APIToken>(
    apiPath,
    { roles: [] }
  );

  const blockTokenX = useEditItem2<APIToken>({
    httpMethod: "post",
    getApiPath: token => `${apiPath}/${token._id}/block`,
  });

  return {
    ...data,
    roles,

    createToken: {
      ...createToken,
      save: () => createToken.save().then(r => { data.reload(); return r; }),
    },

    blockToken: {
      ...blockTokenX,
      startEditing: (token: APIToken) => {
        if(!token.is_blocked) {
          blockTokenX.startEditing(token);
          setTimeout(() => blockTokenX.update({ is_blocked: true }), 100);
        }
      },
      save: () => blockTokenX.save().then(r => { data.reload(); return r; }),
    }
  }
}

export type APITokensManagementData = ReturnType<typeof useAPITokensManagement>;


export const APITokenSchema: Schema = {
  _id: { label_id: "auth.apitokens.fields._id" },
  email: { label_id: "auth.apitokens.fields.email" },
  roles: { label_id: "auth.apitokens.fields.roles", type: FieldType.multiselect },
  created_datetime: { label_id: "auth.apitokens.fields.created_datetime", type: FieldType.datetime },
  is_blocked: { label_id: "auth.apitokens.fields.is_blocked", type: FieldType.bool },
  token: { label_id: "auth.apitokens.fields.token" },
}
