import React, { useState } from "react";
import { EditorPlugin } from "../../../toolympus/components/PowerDoc";
import { CustomElement } from "../../../slate";
import { Editor, Transforms } from "slate";
import { LinkOutlined } from "@mui/icons-material";
import { ReactEditor } from "slate-react";
import { generateCode } from "../../../toolympus/components/PowerDoc/plugins/common";
import { useInboundLinks } from "./InboundLinksContext";
import { findCurrentElement } from "../../../toolympus/components/PowerDoc/slate/SlateHelpers";
import { Instrument } from "../../Instruments/useInstrumentsList";
import { InstrumentSelectionDialog } from "./InstrumentSelectionDialog";

interface EditingContext {
  element: CustomElement;
  editor: Editor;
}


export const useInsertInstrumentLinkPlugin = (): EditorPlugin => {
  const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
  const links = useInboundLinks();

  const openDialog = (editor: Editor, element: CustomElement) => {
      setEditingContext({ editor, element });
  }

  const finishEditing = (r: Instrument) => {
      if(editingContext) {
          // updateMediaFiles(editingContext.editor, editingContext.element, selectedFiles);
          let id = (editingContext.element as any)._id;
          if(!id) {
            id = `${generateCode()}${generateCode()}`;
            const path = ReactEditor.findPath(editingContext.editor, editingContext.element);
            Transforms.setNodes(
              editingContext.editor,
              {
                  _id: id,
              } as any,
              { at: path },
            );
          }
          
          links.addLink(id, "instrument", r._id.toString(), null);
      }
      setEditingContext(null);
  }

  const dialogs = (<>
    {!!editingContext && <InstrumentSelectionDialog
      isOpen={!!editingContext}
      close={() => setEditingContext(null)}
      title="Link an instrument"
      selectRecord={finishEditing}
    />}
    </>);

  return {
    key: "instrument-link",
    commands: [{
        name: "insert-instrument-link",
        invoke: (editor) => {
          const element = findCurrentElement(editor);
          if(element) {
            openDialog(editor, element);
          }
        },
        menu: { section: "insert-item", icon: <LinkOutlined />, label: "Link instrument" },
    }],
    dialogs,
  }
}
