import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EditItemProps, useEditItem2 } from "../../api/useNewItem";
import { useUserSettings } from "../Settings/UserSettingsContext";
import { createSelectSchema, Schema } from "../../hooks/useSchema";
import { Action, useActionWithConfirmation } from "../../api/useAction";
import { generateCode } from "../../api/data";
import { SimpleDialog } from "./Dialogs";
import { FormGrid } from "./Forms";
import { FormControlsForFields } from "../schemed";
import { OptionsColorPicker } from "./OptionsColorSelector";
import { Button, Chip } from "@mui/material";
import { FiltersChipWrapper } from "../schemed/Filtering/useSavedFilters";
import { Tooltip } from "./Tooltip";
import { Edit } from "@mui/icons-material";

interface ChipToSave<T> {
  data: T;
  label: string;
  _id?: string;
  mode?: string;
  color?: string | null;
}


export const ChipToSaveSchema: Schema = {
  label: { label_id: "fieldsfilters.saved.label" },
  mode: createSelectSchema(
    [{ value: "user", label: "user" }, { value: "global", label: "global" }],
    { label_id: "fieldsfilters.saved.mode" }),
}

export interface SavedChipList<T> {
  isAvailable: boolean;
  canUpdateGlobal: boolean;
  saveChip: EditItemProps<ChipToSave<T>>;
  editChip: EditItemProps<ChipToSave<T>>;
  removeChip: Action<void>;
  ensureLoaded: () => void;
  isLoading: boolean;
  user: ChipToSave<T>[];
  global: ChipToSave<T>[];
}

export const useSavedChipList = <T,>(settingsKey: string): SavedChipList<T> => {
  const { formatMessage } = useIntl();
  const userSettings = useUserSettings();

  const saveChip = useEditItem2<ChipToSave<T>>({
    save: (item) => {
      const realItem = { ...item, label: item.label || "Filters", _id: generateCode() };
      delete realItem.mode;
      if(item.mode === "global") {
        const withNewItem = [ ...(userSettings.global[settingsKey] || []), realItem];
        return userSettings.updateGlobal({ [settingsKey]: withNewItem })
        .then(() => realItem);
      } else {
        const withNewItem = [ ...(userSettings.user[settingsKey] || []), realItem];
        return userSettings.updateUser({ [settingsKey]: withNewItem })
          .then(() => realItem);
      }
    }
  });

  const editChip = useEditItem2<ChipToSave<T>>({
    save: (item) => {
      const realItem = { ...item };
      delete realItem.mode;
      const isMy = !!(userSettings.user[settingsKey] as ChipToSave<T>[] || []).find(f => f._id === item._id);
      if(!isMy) {
        const withNewItem = (userSettings.global[settingsKey] as ChipToSave<T>[] || []).map(f => f._id && f._id === realItem._id ? realItem : f);
        return userSettings.updateGlobal({ [settingsKey]: withNewItem })
        .then(() => realItem);
      } else {
        const withNewItem = (userSettings.user[settingsKey] as ChipToSave<T>[] || []).map(f => f._id && f._id === realItem._id ? realItem : f);
        return userSettings.updateUser({ [settingsKey]: withNewItem })
          .then(() => realItem);
      }
    }
  });

  const removeChip = useActionWithConfirmation<void>(() => {
    if(editChip.isEditing && editChip.item?._id) {
      const item = editChip.item;
      const isMy = !!(userSettings.user[settingsKey] as ChipToSave<T>[] || []).find(f => f._id === item._id);

      if(!isMy) {
        const without = (userSettings.global[settingsKey] as ChipToSave<T>[] || []).filter(f => f._id !== item._id);
        return userSettings.updateGlobal({ [settingsKey]: without })
        .then(() => { editChip.cancel() });
      } else {
        const without = (userSettings.user[settingsKey] as ChipToSave<T>[] || []).filter(f => f._id !== item._id);
        return userSettings.updateUser({ [settingsKey]: without })
          .then(() => { editChip.cancel() });
      }
    } else {
      return Promise.resolve();
    }
  }, {
    title: formatMessage({ id: "common.delete" }),
  })

  return {
    isAvailable: userSettings.isAvailable && !!settingsKey,
    canUpdateGlobal: userSettings.isAvailable && userSettings.canUpdateGlobal,
    saveChip,
    editChip,
    removeChip,
    ensureLoaded: () => userSettings.ensureLoaded(),
    isLoading: userSettings.isLoadingUser || userSettings.isLoadingGlobal,
    user: (userSettings.user[settingsKey] || []) as ChipToSave<T>[],
    global: (userSettings.global[settingsKey] || []) as ChipToSave<T>[],
  }
}

interface SaveChipDialogProps<T> {
  title?: ReactNode;
  data: EditItemProps<ChipToSave<T>>;
  isGlobalMyAvailable?: boolean;
}


export const SaveChipDialog = <T,>(props: SaveChipDialogProps<T>) => {
  const { data } = props;

  return (
    <SimpleDialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={props.title || ""}
      save={() => data.save()}
      isSaving={data.isLoading}
      maxWidth="xs"
      fullWidth
      noFullscreen
      submitOnModEnter>
        {!!data.item &&
          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={ChipToSaveSchema}
              fields={[
                ["label", { controlProps: { autoFocus: true }}],
                props.isGlobalMyAvailable ? ["mode", { labelIdPrefix: "fieldsfilters.saved.mode_label" }] : null,
              ]}
              errors={data.errors}
              />
            <OptionsColorPicker
              value={data.item.color}
              update={v => data.update({ color: v })}
              label={<FormattedMessage id="fieldsfilters.saved.color" />}
              />
          </FormGrid>}
    </SimpleDialog>
  )
}

export const EditSavedChipDialog = <T,>(props: SaveChipDialogProps<T> & { remove: () => void }) => {
  const { data } = props;

  return (
    <SimpleDialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={props.title || ""}
      save={() => data.save()}
      simpleExtraActions={<>
        <Button onClick={() => props.remove()}><FormattedMessage id="common.delete" /></Button>
      </>}
      isSaving={data.isLoading}
      maxWidth="xs"
      fullWidth
      noFullscreen
      submitOnModEnter>
        {!!data.item &&
          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={ChipToSaveSchema}
              fields={[
                ["label", { controlProps: { autoFocus: true }}],
              ]}
              errors={data.errors}
              />
            <OptionsColorPicker
              value={data.item.color}
              update={v => data.update({ color: v })}
              label={<FormattedMessage id="fieldsfilters.saved.color" />}
              />
          </FormGrid>}
    </SimpleDialog>
  )
}


interface ChipsInteraction<T> {
  selectChip: (f: ChipToSave<T>) => void;
  appendChip?: (f: ChipToSave<T>) => void;
  editChip?: (f: ChipToSave<T>) => void;
  tooltip?: string;
  tooltipId?: string;
  chips: ChipToSave<T>[];
}

export const ChipsSelection = <T,>(props: ChipsInteraction<T>) => {
  const { chips, selectChip, appendChip, editChip } = props;

  return !chips.length
    ? null
    : (
      <FiltersChipWrapper>
        {chips.map(f => (
          <Tooltip text_id={props.tooltipId} text={props.tooltip}>
            <Chip
              size="small"
              style={f.color ? { background: f.color } : undefined}
              label={f.label}
              onClick={e => {
                if(e.shiftKey && appendChip) {
                  appendChip(f);
                } else {
                  selectChip(f);
                }
              }}
              deleteIcon={<Edit />}
              onDelete={editChip ? () => editChip(f) : undefined}
              />
          </Tooltip>
        ))}
      </FiltersChipWrapper>
    )
}

interface DialogsProps<T> {
  data: SavedChipList<T>;
  isGlobalMyAvailable?: boolean;
  saveTitle?: ReactNode;
  editTitle?: ReactNode;
}

export const ChipsSelectionSaveDialogs = <T,>(props: DialogsProps<T>) => {
  return <>
    <SaveChipDialog
      data={props.data.saveChip}
      isGlobalMyAvailable={props.isGlobalMyAvailable}
      />
    <EditSavedChipDialog
      data={props.data.editChip}
      remove={props.data.removeChip.run}
      />
  </>
}