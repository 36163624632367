import { useMemo } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { FileInfo, useFileList } from "../../../toolympus/components/files"
import { useReorderItemsBySortorder } from "../../../toolympus/components/primitives/ReorderItems";
import { FieldType, Schema, mergeSchema } from "../../../toolympus/hooks/useSchema"

export interface AttachedDocument {
  _id: string;
  filename: string;
  title: string;
  comment: string;
}

type AttachedWithFileInfo = AttachedDocument & { fileinfo: FileInfo };

const fileInfoToDocument = (fi: FileInfo): AttachedDocument & { fileinfo: FileInfo } => ({
  _id: fi._id,
  filename: fi.filename,
  // case_record_id: +(fi.meta?.case_record_id || "-1"),
  title: fi.meta?.title || "",
  comment: fi.meta?.comment || "",
  fileinfo: fi,
});


const DocumentSchema: Schema = {
  title: { label: "Title" },
  comment: { label: "Comment", type: FieldType.textlong },
}


const sortBySortOrder = (a: AttachedWithFileInfo, b: AttachedWithFileInfo) => {
  const soA = a.fileinfo?.meta?.sortorder || 0;
  const soB = b.fileinfo?.meta?.sortorder || 0;

  if(soA === soB) {
    return a.title === b.title
      ? a._id < b._id ? -1 : 1
      : a.title < b.title
        ? -1
        : 1;
  }

  return soA < soB ? -1 : 1;
}


export interface AttachedDocumentsConfig {
  sortBySortOrder?: boolean;
}

export const useAttachedDocuments = (apiPath: string, parentId: number, cfg?: AttachedDocumentsConfig) => {
  const filelist = useFileList(`${apiPath}/${parentId}/document`, undefined, {
    noLoad: parentId < 0,
  });

  const editItem = useEditItem2<AttachedDocument>({
    getApiPath: doc => `${apiPath}/${parentId}/document/${doc._id}/info`,
  });

  const updateFileSortOrder = (docId: string, sortOrder: number) => {
    return apiFetch<FileInfo>(`${apiPath}/${parentId}/document/${docId}/info`, "put", {
      sortorder: sortOrder,
    })
  }

  const reorderItems = useReorderItemsBySortorder<AttachedWithFileInfo>({
    updateItemOrder: (doc, sortorder) => updateFileSortOrder(doc._id, sortorder),
    onSave: () => filelist.reload(),
  });

  const files = useMemo(() => {
    const processed = filelist.data.map(fileInfoToDocument);
    
    if(cfg?.sortBySortOrder) {
      processed.sort(sortBySortOrder);
    }
    return processed;
  }, [filelist.data, cfg?.sortBySortOrder]);

  return {
    ...filelist,
    schema: mergeSchema(filelist.schema, DocumentSchema),
    data: files,
    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { filelist.reload(); return x; }),
    },

    reorderItems,
    sortBySortOrder: !!cfg?.sortBySortOrder,
  }
}

export type AttachedDocumentsData = ReturnType<typeof useAttachedDocuments>;
