import React, { ReactNode, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Config {
  onBecameVisible?: () => void;
  isDisabled?: boolean;
}

interface VisilibilityTrigger {
  anchor: ReactNode;
}

export const useVisibilityTrigger = (config?: Config): VisilibilityTrigger & { isVisible: boolean } => {
  const { onBecameVisible, isDisabled } = config || {};
  const [isVisible,setIsVisible] = useState<boolean>(false);


  const anchor = !isDisabled ? (
    <VisibilitySensor onChange={(isVisible) => {
        setIsVisible(isVisible);
        if(isVisible && onBecameVisible) {
            onBecameVisible();
        }}}>
        <div className="visibility-trigger" style={{ height: "1px", width: "100%" }} />
    </VisibilitySensor>)
    : null;

  return {
    anchor,
    isVisible,
  }
}
