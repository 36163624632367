import { EditorPlugin } from "../../../toolympus/components/PowerDoc";
import { getCurrentFirstLevelElement } from "../../../toolympus/components/PowerDoc/slate/SlateHelpers";
import { ModerationFeedData } from "./useDocumentModeration";

interface Config {
  commentCommandLabel?: string;
}

export const createModerationCommentPlugin = (moderationFeed: ModerationFeedData, cfg?: Config): EditorPlugin => {
  return ({
    key: "start-comment",
    commands: [{
      name: "start-comment",
      menu: { section: "insert-item", label: cfg?.commentCommandLabel || "Add a comment" },
      invoke: (editor) => {
        const path = editor.selection?.anchor;
        
        const commentData: any = {};
        if(path) {
          commentData.editor_path = path;
        }
        const element = getCurrentFirstLevelElement(editor);
        const id = (element as any)?._id;
        if(id) {
          commentData.editor_id = id;
        }

        moderationFeed.newComment.startEditing({
          comment: "",
          extra: commentData,
        });
      }
    }],
  })
}
