import { useEffect } from "react"
import { ContentSubIdQueryParam } from "./LinksBlips";
import { ContentSubIdDataAttribute } from "../../Administration/Editor/BlockElement";

const locateAndScroll = () => {
  const param = new URLSearchParams(window.location.search).get(ContentSubIdQueryParam);
  if(param) {
    const element = document.querySelector(`[${ContentSubIdDataAttribute}="${param}"]`);

    if(element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      return true;
    }
  }
  return false;

}

export const useScrollToContentSubIdFromQuery = () => {
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      const success = locateAndScroll();
      if(!success) {
        setTimeout(() => locateAndScroll(), 1500);
      }
    }, 250);
    return () => clearTimeout(timeout);
  }, []);
}
