import React, { ReactNode, useState } from 'react';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { DialogState, useThrottledCapture } from '../../../toolympus/components/primitives';
import { Instrument, InstrumentApiPath, useInstrumentSchema } from '../../Instruments/useInstrumentsList';
import { SelectItemDialog } from '../../../toolympus/components/primitives/SelectItemDialog';

const useInstrumentSearch = () => {
  const [search,setSearch] = useState<string>("");
  const searchSubmitted = useThrottledCapture(search, 700);
  const data = useLoadedData<Instrument[]>(`${InstrumentApiPath}?title_search=${searchSubmitted}`, [], !!searchSubmitted);

  const schema = useInstrumentSchema();

  return {
    data,
    search,
    schema,
    isSubmitted: search === searchSubmitted,
    setSearch,
  }
}


interface Props extends Omit<DialogState, "open"> {
  title: ReactNode;
  isLoading?: boolean;
  selectRecord: (instrument: Instrument) => void;
}

export const InstrumentSelectionDialog = (props: Props) => {
  const data = useInstrumentSearch();

  return (
    <SelectItemDialog
      available={data.data.data}
      close={() => props.close()}
      isOpen={props.isOpen}
      fields={[
        ["title"],
        ["is_published"]
      ]}
      filter={data.search}
      setFilter={v => data.setSearch(v)}
      isLoading={data.data.isLoading}
      schema={data.schema}
      title={props.title}
      select={r => props.selectRecord(r)}
      />
  );
}
