import React from 'react';
import styled from '@emotion/styled';
import { DisplayProps } from './FormControlProps';

export const TextDisplay = (props: DisplayProps) => <>{(props.row || {})[props.field] || ""}</>;


const TextLongLine = styled.p`
    margin: 0;
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }
`;

export const TextLongDisplay = (props: DisplayProps) => {
    const value = (props.row || {})[props.field] as string || "";
    return <>{(value as string || "").split("\n").map((line,idx) => <TextLongLine key={idx}>{line}</TextLongLine>)}</>
}

export const TextLongDisplaySimple = (props: DisplayProps) => {
  const value = (props.row || {})[props.field] as string || "";
  return <span style={{ whiteSpace: "pre-line"}}>{value}</span>
}
