import { IconButton, IconButtonProps, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { ReactNode, useState } from 'react';
import { StrippedIconButton } from './StrippedButtons';

interface ActionOptions {
  disabled?: boolean;
}

interface Props extends Pick<IconButtonProps, "size" | "color"> {
  icon?: ReactNode;
  noAutoClose?: boolean;
  actions: ([string,ReactNode, () => void] | [string,ReactNode, () => void, ActionOptions] | null)[];
  strippedButton?: boolean;
  keepMenu?: boolean;
  className?: string;
}

/**
 * An icon button that opens up a menu with actions.
 * Each action comes in the form of: string key, label, click handler
 */
export const MoreActionsMenuButton = (props: Props) => {
  const [menuAnchor,setMenuAnchor] = useState<any>(null);

  const { actions, icon, noAutoClose, strippedButton, keepMenu, ...otherProps } = props;
  
  return (<>
    {strippedButton
      ? <StrippedIconButton className={props.className} onClick={e => setMenuAnchor(e.target)}>{icon || <MoreVert />}</StrippedIconButton>
      : <IconButton size="small" className={props.className} {...otherProps} onClick={e => setMenuAnchor(e.target)}>{icon || <MoreVert />}</IconButton>}
    {(!!menuAnchor || keepMenu) &&
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted={keepMenu}>
        {(actions.filter(x => !!x) as [string,ReactNode, () => void, ActionOptions | undefined][])
          .map(([key,label,action,options]) => (
            <MenuItem key={key} disabled={options?.disabled} onClick={() => { action(); if(!noAutoClose) { setMenuAnchor(null); }}}>{label}</MenuItem>
          ))}
      </Menu>}
  </>)
}
