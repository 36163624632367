import React from 'react';
import { Buttons, DownloadFileLink } from '../primitives';

interface Props {
  filesText?: string;
  downloadFile: (id: string, filename?: string) => Promise<void>;
}

export const FormMultiFileView = (props: Props) => {
  const files = (props.filesText || "").split("\n").map(s => s.trim()).filter(x => !!x)
    .map(s => s.split(";").map(s => s.trim())).map(([id,filename]) => ({ id, filename }));


  return (
    <Buttons vertical>
      {files.map(f => (
        <DownloadFileLink downloadFile={() => props.downloadFile(f.id, f.filename)}>
          {f.filename}
        </DownloadFileLink>
      ))}  
    </Buttons>
  );
}
