import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormats } from '.';
import { DateInternalFormat, DateTimeInternalFormat, TimeInternalFormat } from '../../api/datetimeUtil';
import { utc } from '../timezone';
import { expandProps, FormControlProps } from './FormControlProps';
import { FieldSchema } from '../../hooks/useSchema';

export declare type ParsableDate = object | string | number | Date | null | undefined;

const { toLocal, toServer } = utc;

export const DateTimeEdit = (props: FormControlProps) => {
    const { row, field, onChange, value, label, controlProps, error, extraProps } = expandProps(props);
    const intl = useIntl();
    const formats = useFormats();

    const isConvert = !!extraProps?.utcToLocal;

    const toUser: (v: ParsableDate) => ParsableDate = useMemo(() => {
      return isConvert ? (v => v ? toLocal(v) : v) : (v => v);
    }, [isConvert]);

    const val = useMemo(() => value ? moment(toUser(value)) : null, [value, toUser]);

    const toInternal: (v: Moment) => Moment = isConvert ? (v => toServer(v)) : (v => v);

    return (
      <DateTimePicker
        variant="inline"
        format={formats.datetime}
        label={label}
        value={val}
        error={error}
        invalidDateMessage={intl.formatMessage({ id: "common.invalid_date_format"})}
        KeyboardButtonProps={{ size: "small" }}
        ampm={false}
        slotProps={{ textField: { fullWidth: true, size: "small", InputProps: controlProps?.InputProps }}}
        {...controlProps}
        InputProps={{
            ...(controlProps.InputProps || {}),
            readOnly: controlProps.readOnly,
        }}
        onChange={(date) => {
            const converted = date ? toInternal(date as Moment) : date as null;
            const formatted = converted ? converted.format(DateTimeInternalFormat) : null;
            onChange(row, {[field]: formatted })
        }}
        />
      );
}


export const DateEdit = (props: FormControlProps) => {
    const { row, field, onChange, value, label, controlProps, error } = expandProps(props);
    const intl = useIntl();
    const formats = useFormats();

    const val = useMemo(() => value ? moment(value) : null, [value]);
    
    return (
      <DatePicker
        disableToolbar
        variant="inline"
        format={formats.date}
        label={label}
        value={val || null}
        error={error}
        invalidDateMessage={intl.formatMessage({ id: "common.invalid_date_format"})}
        KeyboardButtonProps={{ size: "small" }}
        slotProps={{ textField: { fullWidth: true, size: "small" }}}
        {...controlProps}
        InputProps={{
            ...(controlProps.InputProps || {}),
            readOnly: controlProps.readOnly,
        }}
        onChange={(date) => onChange(row, {[field]: date ? (date as Moment).format(DateInternalFormat) : null})}
        />
      );
}

export const TimeEdit = (props: FormControlProps) => {
  const { row, field, onChange, value, label, controlProps, error } = expandProps(props);
  const intl = useIntl();
  
  const val = useMemo(() => value ? moment(value) : null, [value]);

  return (
    <TimePicker
      disableToolbar
      variant="inline"
      format={"HH:mm"}
      label={label}
      value={val || null}
      error={error}
      invalidDateMessage={intl.formatMessage({ id: "common.invalid_date_format"})}
      KeyboardButtonProps={{ size: "small" }}
      slotProps={{ textField: { fullWidth: true, size: "small" }}}
      {...controlProps}
      InputProps={{
          ...(controlProps.InputProps || {}),
          readOnly: controlProps.readOnly,
      }}
      onChange={(date) => onChange(row, {[field]: date ? (date as Moment).format(DateTimeInternalFormat) : null})}
      />
    );
}

interface DateAndTimeProps extends FormControlProps {
  noDate?: boolean;
  noTime?: boolean;
  dateSchema?: FieldSchema;
  timeSchema?: FieldSchema;
}

export const DateAndTimeEdit = (props: DateAndTimeProps) => {
  const onDateChange: FormControlProps["onChange"] = (o,c) => {
    if(o[props.field] && c[props.field] && !o[props.field].includes("Invalid") && !c[props.field].includes("Invalid")) {
      const timeOld = moment(o[props.field]).format(TimeInternalFormat);
      const dateNew = moment(c[props.field]).format(DateInternalFormat);
      props.onChange(o, { ...c, [props.field]: `${dateNew} ${timeOld}`});
    } else {
      props.onChange(o,c);
    }
  }

  return (
    <>
      {!props.noDate &&
        <DateEdit
          field={props.field}
          row={props.row}
          onChange={onDateChange}
          schema={props.dateSchema || props.schema}
          errors={props.errors}
          extraProps={props.extraProps}
          />}
      {!props.noTime &&
        <TimeEdit
          field={props.field}
          row={props.row}
          onChange={props.onChange}
          schema={props.timeSchema || props.schema}
          errors={props.errors}
          extraProps={props.extraProps}
          />}
    </>
  )
}