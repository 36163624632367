import { Editor, Transforms, Element as SlateElement, Path, } from 'slate';
import { ParagraphElementType } from '../../slate/BlockElements';
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { AlwaysActiveSectionName } from '../../slate/Menu';


export const withLists = (editor: Editor) => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
        const [listItem] = Editor.nodes(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "li",
        });

        if(listItem) {
            const [item, itemPath] = listItem;
            const children = (item as any).children || [];
            
            // if we're hitting Enter in an empty list item, remove the empty item,
            // and move into a new paragraph right after the list

            if(children.length === 1 && children[0].text === "") {
                //  TODO: this doesn't respect nested lists
                const injectionPath = itemPath.slice(0, itemPath.length - 1);
                injectionPath[injectionPath.length - 1] += 1;
                
                Transforms.removeNodes(
                    editor,
                    { at: itemPath },
                );
                Transforms.insertNodes(
                    editor,
                    [{
                        type: ParagraphElementType,
                        children: [{ text: "", }],
                    }],
                    {
                        select: true,
                        at: injectionPath,
                    });
                return;
            } 
        }

        insertBreak();
    }

    return editor;
}

export const withHeadings = (editor: Editor) => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
        const [heading] = Editor.nodes(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && !!(n.type || "").match(/h\d/),
        });

        if(heading) {
            const [item, itemPath] = heading;
            const children = (item as any).children || [];
            
            // if we're hitting Enter in an empty heading element, convert to paragraph
            
            if(children.length === 1 && children[0].text === "") {
                Transforms.removeNodes(
                    editor,
                    { at: itemPath },
                );
                Transforms.insertNodes(
                    editor,
                    [{
                        type: ParagraphElementType,
                        children: [{ text: "", }],
                    }],
                    {
                        select: true,
                        at: itemPath,
                    });
                return;
            }
        }

        insertBreak();
    }

    return editor;
}

export const withNonEmptyConstraint = (editor: Editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = entry => {
        const [node, path] = entry;
        if(Editor.isEditor(node) && path && path.length === 0 && !node.children.length) {
            Transforms.insertNodes(editor, [
                {
                    type: ParagraphElementType,
                    children: [{ text: "" }],
                },
            ]);
            return;
        }
        normalizeNode(entry);
    }
    return editor;
}

const withElements = (editor: Editor) => withNonEmptyConstraint(withHeadings(withLists(editor)));

export const BasicCommandsPlugin: EditorPlugin = {
    key: "basic-commands",
    inject: withElements,
    commands: [
        {
            name: "non-breaking-space",
            invoke: e => {
                e.insertText('\xA0');
            },
            menu: {
                section: "elements",
                label_id: "powerdoc.elements.non_breaking_space",
            }
        },
        {
            name: "new-line-after",
            hotkey: "alt+shift+enter",
            invoke: e => {
                e.insertNode({
                    type: ParagraphElementType,
                    children: [{ text: "", }],
                });
            },
            menu: {
                section: AlwaysActiveSectionName,
                label_id: "powerdoc.elements.new_line_after",
            }
        },
        {
            name: "new-line-before",
            hotkey: "alt+shift+backspace",
            invoke: e => {
              const { selection } = e;

              if (selection) {
                const [, currentPath] = Editor.node(e, selection);
                const parentPath = Path.parent(currentPath);
                
                const newParagraph = {
                  type: ParagraphElementType,
                  children: [{ text: "", }],
                };
                
                Transforms.insertNodes(e, newParagraph, {
                  at: [...parentPath],
                  select: true,
                });
              }
            },
            menu: {
                section: AlwaysActiveSectionName,
                label_id: "powerdoc.elements.new_line_before",
            }
        },
        {
            name: "delete-block",
            hotkey: "alt+shift+delete",
            invoke: e => {
                const [block] = Editor.nodes(e, {
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n),
                });
                if(block) {
                    const [,path] = block;
                    Transforms.delete(
                        e,
                        { at: path },
                    );
                }
            },
            menu: {
                section: AlwaysActiveSectionName,
                label_id: "powerdoc.elements.delete_block",
            }
        },
    ],
}