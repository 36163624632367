import { useEffect, useMemo, useState } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { FieldType, Schema } from "../../hooks/useSchema";
import { useFields } from "../schemed";
import { ConfigurableForm, FlattennedSubmission, FormSubmission } from "./types";
import ExcelJS from "exceljs";
import { utc } from "../timezone";
import { apiFetch, downloadBuffer, downloadFile } from "../../api/core";
import { useIntl } from "react-intl";
import { useTextFilter } from "../schemed/Filtering/useTextFilter";
import { useConfigurableFormSubmissionReview } from "./useConfigurableFormSubmissionReview";
import { cropText } from "../primitives";
import { useMassAction } from "../Actions";
import { ActionInstance } from "../Actions/actions.types";

const BaseSubmissionSchema: Schema = {
    created_at: {
        type: FieldType.datetime,
        label_id: "forms.submission.created_at",
    },
    lang: {
        type: FieldType.text,
        label_id: "forms.submission.lang",
    },
    processing_status: {
        type: FieldType.select,
        label_id: "forms.submission.processing_status",
        values: [
            { value: "new", label: "new" },
            { value: "processed", label: "processed" },
        ],
    },
    comment: {
        type: FieldType.textlong,
        label_id: "forms.submission.comment",
    },
}

export const fieldtypeToSchemaType = (fieldType: string): FieldType => {
    switch(fieldType) {
        case "boolean":
            return FieldType.bool;
        case "date":
            return FieldType.date;
        case "number":
            return FieldType.number;
        case "text_multiline":
        case "select_multi":
        case "rank":
            return FieldType.textlong;
        default:
            return FieldType.text;
    }
}

const exportSubmissions = (form: ConfigurableForm, localizer: (k: string) => string, submissions: FormSubmission[]) => {
    const workbook = new ExcelJS.Workbook();
    const titleClean = form.title.replaceAll(/([:?*]|\[|\]|\\|\/)+/gi, " ");
    const sheet = workbook.addWorksheet(titleClean);

    sheet.columns = [
        { header: localizer(BaseSubmissionSchema.created_at.label_id || ""), key: "created_at" },
        { header: localizer(BaseSubmissionSchema.processing_status.label_id || ""), key: "processing_status" },
        { header: localizer(BaseSubmissionSchema.comment.label_id || ""), key: "comment" },
        { header: localizer(BaseSubmissionSchema.lang.label_id || ""), key: "lang" },
        ...form.fields.map(({ _id, title }) => ({ header: title, key: _id })),
    ];
  
    submissions.forEach(submission => {
        const { fields, created_at, ...other } = submission;
        sheet.addRow({
            ...fields,
            created_at: utc.toLocal(created_at).toDate(),
            ...other,
        })
    });
  
    workbook.xlsx
      .writeBuffer({ base64: true } as any)
      .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `${form.title}.xlsx`));
  
  }

const useSelectSubmissions = (fulllist: FlattennedSubmission[]) => {
  const [selected,setSelected] = useState<Record<string, boolean>>({});

  const isSelected = (submission: FlattennedSubmission) => !!selected[submission._id];

  const toggleSelected = (submission: FlattennedSubmission) => setSelected(s => ({ ...s, [submission._id]: !s[submission._id] }));
  const updateSelected = (submission: FlattennedSubmission, selected: boolean) => setSelected(s => ({ ...s, [submission._id]: selected }));

  const selectedItems = useMemo(() => {
    if(!Object.keys(selected).length) {
      return [];
    }
    return fulllist.filter(s => !!selected[s._id]);
  }, [fulllist, selected]);

  const [isAllSelected, toggleAllSelected] = useMemo(() => {
    const isAllSelected = fulllist.length === selectedItems.length;
    const toggle = () => setSelected(isAllSelected
      ? {}
      : fulllist.reduce<Record<string, boolean>>((r,submission) => { r[submission._id] = true; return r; }, {}));
    return [isAllSelected, toggle];
  }, [fulllist, selectedItems]);

  return {
    selected,
    isSelected,
    toggleSelected,
    updateSelected,
    selectedItems,

    isAllSelected,
    toggleAllSelected,
  }
}

export const useConfigurableFormSubmissions = (apiPath: string, form: ConfigurableForm, doLoad: boolean) => {
    const [loadRequested, setLoadRequested] = useState<boolean>(doLoad);

    const [view, setView] = useState<string>("form-new");

    const realApiPath = apiPath.replace("/config", "");

    const data = useLoadedData<FormSubmission[]>(`${realApiPath}/${form._id}/submission?view=${view}`, [], loadRequested && !!form._id);

    const { formatMessage } = useIntl();

    useEffect(() => {
        if(doLoad) {
            setLoadRequested(true);
        }
    }, [doLoad]);

    const schema: Schema = useMemo(() => form.fields.reduce<Schema>(
        (r,f) => {
            r[f._id] = {
                type: fieldtypeToSchemaType(f.fieldtype),
                label: cropText(f.title, 50),
            }
            return r;
        },
        { ...BaseSubmissionSchema }
    ), [form]);

    const fields = useMemo(() => form.fields.map(f => f._id), [form]);

    const itemsFlattenned = useMemo(() => data.data.map(item => {
        const { fields, ...other } = item;
        return { ...other, ...fields };
    }), [data.data]);

    const selection = useSelectSubmissions(itemsFlattenned);

    const massAction = useMassAction<FlattennedSubmission>();

    const prepareMassAction = (action: ActionInstance) => {
      massAction.prepareAction({
        items: selection.selectedItems,
        label: action.label || action._id,
        action: item => apiFetch(`${realApiPath}/${form._id}/submission/${item._id}/action/${action._id}`, "post"),
      })
    }

    const fieldsConfig = useFields({
        defaultFields: [
            "selected",
            "created_at",
            "processing_status",
            "comment",
            "lang",
            ...fields,
        ],
        schema,
        extraSettings: {
            processing_status: { labelIdPrefix: "forms.submission.processing_status_value" },
            created_at: { utcToLocal: true },
            selected: { label: " "},
        },
        outOfSchemaFields: ["selected"],
        storageKey: `__tl_forms_${form._id}`,
    });

    useEffect(() => {
        fieldsConfig.changeDefaultFields([
            "created_at",
            "lang",
            "processing_status",
            "comment",
            ...fields,
        ]);
    // because fields are an array, which is recreated whenever the form object changes
    // it would cause infinite effect->rerender->effect->... loop
    // when we try to update anything in non-default locale 
    // (because the form object yielded by localizer is new on every render)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const textFilterFn = useMemo(() => {
        const textFields = form.fields.filter(f => f.fieldtype === "text" || f.fieldtype === "email").map(f => f._id);

        return (s: FlattennedSubmission) => {
            return `${textFields.map(f => s[f] || "").join(" ")} ${s.comment || ""}`;
        }
    }, [form]);

    const filter = useTextFilter<FlattennedSubmission>(textFilterFn);

    const review = useConfigurableFormSubmissionReview(realApiPath, form);

    const filesApiPathPath = form.files_api_path ? `/api${form.files_api_path}` : null;

    const downloadFormFile = (fileId: string, filename?: string) => {
        if(filesApiPathPath && fileId) {
            return downloadFile(`${filesApiPathPath}/${fileId}`, filename);
        } else {
            return Promise.resolve();
        }
    }

    const removeSubmission = (s: FormSubmission) => {
      return apiFetch(`${realApiPath}/${form._id}/submission/${s._id}`, "DELETE")
        .then(() => data.reload());
    }

    return {
        ...data,
        data: filter.filterData(itemsFlattenned),
        dataRaw: data.data, 
        form,

        view,
        setView,
        filter,

        schema,
        fields,
        fieldsConfig,

        exportSubmissions: () => exportSubmissions(form, id => formatMessage({ id }), data.data),

        review,
        removeSubmission,
        downloadFormFile,

        selection,
        massAction,
        prepareMassAction,
    }
}

export type ConfigurableFormSubmissionsData = ReturnType<typeof useConfigurableFormSubmissions>;
