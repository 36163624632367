import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { apiFetch } from '../../../toolympus/api/core';
import { useItemActionWithConfirmation } from '../../../toolympus/api/useAction';

export interface RecordLink {
  this_record_type: string;
  this_record_id: string;
  this_record_sub_id: string | null;

  that_record_type: string;
  that_record_id: string;
  that_record_sub_id: string | null;
  description?: string;
  link_id?: string;
}

export interface LinkedDescription {
  record_type: string;
  record_id: string;
  description: string;
}

export interface IInboundLinksContext {
  addLink: (thisSubId: string | null, otherType: string, otherId: string, otherSubId: string | null) => Promise<void>;
  removeLink: (link: RecordLink) => void;
  links: RecordLink[];
}

const InboundLinksContext = React.createContext<IInboundLinksContext>({
  links: [],
  addLink: () => Promise.resolve(),
  removeLink: () => {},
});

export const useInboundLinks = () => useContext(InboundLinksContext);

interface Props {
  this_record_type: string;
  this_record_id: string;
}

export const useInboundLinksData = (props: Props) => {
  const data = useLoadedData<RecordLink[]>(
    `/api/record-link/${props.this_record_type}/${props.this_record_id}`,
    [],
    !!props.this_record_type && !!props.this_record_id);

  const [describedLinks, setDescribedLinks] = useState<RecordLink[] | null>(null);

  useEffect(() => {
    const request = data.data.map(x => ({ record_type: x.that_record_type, record_id: x.that_record_id }));
    if(request.length) {
      apiFetch<LinkedDescription[]>("/api/entity/description", "post", { records: request })
        .then(x => {
          setDescribedLinks(data.data.map(link => ({
            ...link,
            description: x.find(d => d.record_type === link.that_record_type && d.record_id === link.that_record_id)?.description,
          })));
        })
    } else {
      setDescribedLinks([]);
    }
  }, [data.data]);

  return {
    links: describedLinks || data.data,
    isLoading: data.isLoading,
    reload: data.reload,
  };
}

export type InboundLinksData = ReturnType<typeof useInboundLinksData>;



export const InboundLinksContextProvider = (props: PropsWithChildren<Props>) => {
  const { links, reload } = useInboundLinksData(props);

  const removeLink = useItemActionWithConfirmation<RecordLink, {}>(
    link => {
      return apiFetch<{}>(
        `/api/record-link/${link.link_id}`,
        "delete",
      ).then(x => {
        reload();
        return x;
      })
    }, {
      title: "Remove link",
      memoTrigger: `${props.this_record_id}_${props.this_record_type}`
    }
  );

  const ctx: IInboundLinksContext = useMemo(() => {
    return {
      links,
      addLink: (thisSubId, otherType, otherId, otherSubId) => {
        return apiFetch<{}>(
          `/api/record-link`,
        "post",
        {
          left: { record_type: props.this_record_type, record_id: props.this_record_id, record_sub_id: thisSubId },
          right: { record_type: otherType, record_id: otherId, record_sub_id: otherSubId },
        }
      ).then(() => {
        reload();
      });
      },
      removeLink: l => removeLink.run(l),
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  return (
    <InboundLinksContext.Provider value={ctx}>
      {props.children}
    </InboundLinksContext.Provider>
  );
}
