import { useEffect } from "react";
import { useUserSettings } from "../../../toolympus/components/Settings/UserSettingsContext"
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";

export const DefaultModeratorIdField = "default_moderator_id";

const ModerationSettingsSchema: Schema = {
  [DefaultModeratorIdField]: { type: FieldType.dictionarySelect, dictionary: "Users", label: "Default moderator" },
}

export const useModerationSettings = () => {
  const { global, updateGlobal, ensureLoaded, isLoadingGlobal, canUpdateGlobal } = useUserSettings();

  useEffect(() => {
    ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    canEdit: canUpdateGlobal,
    data: global,
    update: updateGlobal,
    isLoading: isLoadingGlobal,
    schema: ModerationSettingsSchema,
  }
}
