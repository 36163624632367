import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogState, useDialogState } from './Dialogs';
import { DictionaryRecord } from '../../hooks/useDictionaries';
import { Buttons, OccupyFreeSpace } from './ActionRow';
import { Button, DialogProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from './Forms';
import { CheckboxBare } from '../schemed/Checkbox';
import { TextFilter, useTextFilter } from '../schemed/Filtering/useTextFilter';
import { SearchField } from './SearchField';
import { PseudoLink2 } from './Common.styles';
import { ChipsSelection, ChipsSelectionSaveDialogs, useSavedChipList } from './useSavedChipList';
import { generateCode } from '../../api/data';

type OptionType = Pick<DictionaryRecord, "code" | "label">;

interface DataProps {
  update: (v: string[] | null) => void;
  options: OptionType[];
}

interface MultiSelectorDialogDataT {
  dialog: DialogState;
  filter: TextFilter<OptionType>;
  selected: string[];
  startEditing: (selected: string[] | null | undefined) => void;
  completeEditing: () => void;
  options: OptionType[];
  
  update: (v: string[]) => void;
  select: (code: string, selected: boolean) => void;
  selectMultiple: (codes: string[], selected: boolean) => void;
  selectAll: (selected: boolean) => void;
}


export const useMultiSelectDialog = (props: DataProps): MultiSelectorDialogDataT => {
  const [selected,setSelected] = useState<string[]>([]);
  const dialog = useDialogState();

  const filter = useTextFilter<OptionType>(o => o.label);

  useEffect(() => {
    if(!dialog.isOpen) {
      setSelected(x => x.length ? [] : x);
    }
  }, [dialog.isOpen]);

  const options = useMemo(() => {
    return filter.filterData(props.options);
  }, [filter, props.options]);


  return {
    dialog,
    options,
    selected,
    filter,

    startEditing: v => {
      setSelected(v ? [...v] : []);
      dialog.open();
    },
    completeEditing: () => {
      props.update(selected);
      dialog.close();
    },

    update: (v: string[]) => {
      setSelected(v);
    },
    select: (code: string, selected: boolean) => {
      setSelected(x => selected
        ? x.includes(code) ? x : [...x, code]
        : x.includes(code) ? x.filter(c => c !== code) : x
      );
    },
    selectMultiple: (codes: string[], selected: boolean) => {
      setSelected(x => selected
        ? [...x, ...codes.filter(c => !x.includes(c))]
        : x.filter(c => !codes.includes(c))
      );
    },
    selectAll: (selected: boolean) => {
      setSelected(selected ? options.map(o => o.code) : []);
    },
  }
}


const FilterButtons = styled(Buttons)`
  font-size: 1rem;

  ${PseudoLink2} {
    text-transform: lowercase;
  }
`;

const FooterButtons = styled(Buttons)`
  flex: 1 0 auto;
  align-items: baseline;

  ${PseudoLink2} {
    text-transform: lowercase;
  }
`;

interface Props {
  data: MultiSelectorDialogDataT;
  title: ReactNode;
  columns?: number;
  size?: DialogProps["maxWidth"];
  savedChipsKey?: string;
  saveChipTitle?: ReactNode;
  editChipTitle?: ReactNode;
}

export const MultiSelectorDialog = (props: Props) => {
  const { data } = props;

  const savedChips = useSavedChipList<string[]>(props.savedChipsKey || "no_key");
  const [showSelectedOnly, setShowSelectedOnly] = useState<boolean>(false);

  useEffect(() => {
    if(props.savedChipsKey) {
      savedChips.ensureLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.savedChipsKey]);


  return (<>
    <Dialog
      {...data.dialog}
      maxWidth={props.size}
      dialogTitle={props.title}
      noFullscreen
      afterTitle={<FormGrid columns="1fr" noMargin gap="dense">
        <SearchField
          filter={data.filter.filter}
          setFilter={data.filter.setFilter}
          autoFocus
          noButton
          fullWidth
          />

        <FilterButtons>
          {props.savedChipsKey &&
            <ChipsSelection
              chips={savedChips.global}
              editChip={savedChips.canUpdateGlobal ? f => savedChips.editChip.startEditing(f) : undefined}
              selectChip={c => data.selectMultiple(c.data, true)}
              />}
          <OccupyFreeSpace />

          <PseudoLink2 onClick={() => data.selectAll(true)}>
            <FormattedMessage id="common.select_all" />
          </PseudoLink2>
          <PseudoLink2 onClick={() => data.selectAll(false)}>
            <FormattedMessage id="common.deselect_all" />
          </PseudoLink2>
        </FilterButtons>
      </FormGrid>}
      actions={<FooterButtons>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="common.selected_n" values={{ value: data.selected?.length }} />
        </Typography>

        {!!data.selected.length && savedChips.canUpdateGlobal &&
          <PseudoLink2 onClick={() => setShowSelectedOnly(x => !x)}>
            <FormattedMessage id={showSelectedOnly ? "common.show_all" : "common.show_selected_only"} />
          </PseudoLink2>}

        <OccupyFreeSpace />

        {!!data.selected.length && savedChips.canUpdateGlobal &&
          <PseudoLink2 onClick={() => savedChips.saveChip.startEditing({ data: data.selected, label: "", mode: "global", _id: generateCode() })}>
            <FormattedMessage id="common.save_reusable_selection" />
          </PseudoLink2>}

        <Button size="small" onClick={() => data.dialog.close()}><FormattedMessage id="common.cancel" /></Button>
        <Button size="small" onClick={() => data.completeEditing()}><FormattedMessage id="common.confirm" /></Button>
      </FooterButtons>}>

      <FormGrid columns={`repeat(${props.columns || 1}, 1fr)`} forceEvenColumns gap="dense">
        {(showSelectedOnly ? data.options.filter(o => data.selected.includes(o.code)) : data.options)
          .map(o => (
            <CheckboxBare
              value={data.selected.includes(o.code)}
              update={v => data.select(o.code, v)}
              label={o.label}
              />
          ))}
      </FormGrid>
    </Dialog>

    {!!props.savedChipsKey &&
      <ChipsSelectionSaveDialogs
        data={savedChips}
        saveTitle={props.saveChipTitle}
        editTitle={props.editChipTitle}
        />}
  </>);
}
