import { useState } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useFileUploadDialog } from "../../toolympus/components/files";
import { Instrument, InstrumentApiPath, useInstrumentSchema } from "./useInstrumentsList"
import { apiFetch } from "../../toolympus/api/core";
import { useDocumentModeration } from "../Administration/Moderation";
import { useItemActionWithConfirmation } from "../../toolympus/api/useAction";
import { replaceContentNonBreakingSpaces } from "../../toolympus/components/PowerDoc/textHandling";

export const useInstrumentEdit = (id: number) => {
  const data = useCrudItem<Instrument>(`${InstrumentApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useInstrumentSchema();

  const contentFile = useFileUploadDialog({
    apiPath: `${InstrumentApiPath}/${id}/content-file`,
    onUpload: () => {
      data.reload();
    }
  });

  const moderationFeed = useDocumentModeration(`instrument_${id}`);

  const [isModerationSaving, setIsModerationSaving] = useState<boolean>(false);
    const updateDocumentModeration = async (doc: any, newStatus: string) => {
      let d = data.data;
      if(data.hasChanges) {
        d = await data.save();
      }
  
      try {
        setIsModerationSaving(true);
        const updated = await apiFetch<Document>(`${InstrumentApiPath}/${id}/moderation`, "put", { moderation_status: newStatus });
        data.setData({ ...d, ...updated });
        setIsModerationSaving(false);
        moderationFeed.reload();
        return updated;
      } catch(e) {
        setIsModerationSaving(false);
        throw e;
      }
    }

  const removeNonBreakingSpaces = useItemActionWithConfirmation<Instrument, Instrument>(
    doc => {
      return apiFetch<Instrument>(`${InstrumentApiPath}/${doc._id}`, "put", {
        content: replaceContentNonBreakingSpaces(doc.content),
        description: replaceContentNonBreakingSpaces(doc.description),
      })
      .then(d => {
        data.reload();
        return d;
      });
    }, {
      title: "Remove non-breaking spaces",
      canRun: !data.hasChanges,
    }
  )

  return {
    ...data,
    isLoading: data.isLoading || isModerationSaving,
    schema,
    contentFile,
    
    moderationFeed,
    updateDocumentModeration,
    removeNonBreakingSpaces,
  };
}
