import React, { ReactNode, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { TextField, Button, IconButton } from '@mui/material';
import { Autorenew, Clear } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaBreakpoint } from './useMedia';
import { useWindowHotkey } from './hotkeys';
import { useHistory } from 'react-router-dom';

interface SearchFieldProps {
    filter: string;
    setFilter: (v: string) => void;
    doSearch?: () => void;
    fieldLabel?: string;
    buttonLabel?: ReactNode;
    searchOnBlur?: boolean;
    noButton?: boolean;
    noClear?: boolean;
    small?: boolean;
    autoFocus?: boolean;
    fullWidth?: boolean;
    noFocusHotkey?: boolean;
    queryParam?: string;
    syncQuery?: boolean;
}

const SearchWrapper = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    width: ${props => props.fullWidth ? "100%" : "auto"};
`;

export const SearchField = (props: SearchFieldProps) => {
    const doSearch = props.doSearch || (() => { });
    const isMobile = useMediaBreakpoint("down", "sm");
    const { formatMessage } = useIntl();
    const history = useHistory();

    const fieldRef = useRef(null);

    useWindowHotkey("alt+q", () => {
        if(!props.noFocusHotkey && fieldRef.current && (fieldRef.current as any).focus) {
            (fieldRef.current as any).focus();
        }
    }, {
        noPreventDefault: props.noFocusHotkey,
    });

    useEffect(() => {
      if(props.queryParam) {
        const fromQuery = new URLSearchParams(history.location.search).get(props.queryParam);
        if(fromQuery && !props.filter) {
          props.setFilter(fromQuery);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.queryParam]);

    useEffect(() => {
      if(props.queryParam && props.syncQuery) {
        const fromQuery = new URLSearchParams(history.location.search).get(props.queryParam);
        if(fromQuery !== props.filter) {
          const updatedQuery = new URLSearchParams(history.location.search);
          if(props.filter) {
            updatedQuery.set(props.queryParam, props.filter);
          } else {
            updatedQuery.delete(props.queryParam);
          }
          history.replace({ hash: history.location.hash, pathname: history.location.pathname, search: updatedQuery.toString() });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.queryParam, props.filter, props.syncQuery]);

    return (
        <SearchWrapper fullWidth={props.fullWidth}>
            <TextField
                inputRef={fieldRef}
                size={props.small ? "small" : "medium"}
                value={props.filter}
                onChange={e => props.setFilter(e.target.value)}
                onKeyPress={e => { if(e.key === "Enter") { doSearch(); e.stopPropagation(); e.preventDefault(); }}}
                placeholder={props.fieldLabel || formatMessage({ id: "searchField.label" })}
                onBlur={props.searchOnBlur ? doSearch : () => { }}
                autoFocus={props.autoFocus}
                fullWidth={props.fullWidth}
                InputProps={(props.noClear || isMobile) ? {} : {
                    endAdornment: <IconButton style={{ visibility: props.filter ? "visible" : "hidden" }} size="small" onClick={() => props.setFilter("")}><Clear /></IconButton>
                }}
                />
            {!props.noButton && <Button color="primary"
                startIcon={<Autorenew />}
                onClick={!props.searchOnBlur ? doSearch : () => { }} >
                    {props.buttonLabel || <FormattedMessage id="searchField.buttonLabel" />}
            </Button>}
        </SearchWrapper>
    )
}
