export const getScrollParent = (node: Node | null): any => {
  if(!node) {
    return null;
  }

  if ((node as any).scrollHeight > (node as any).clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export const scrollParentToTop = (node: any) => {
  if(!node || !node.scrollHeight) {
    return;
  }
  
  const parent = getScrollParent(node);
  if(parent) {
    parent.scrollTo({ top: 0, behavior: "smooth" });
  }
}