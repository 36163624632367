import { ReactEditor } from "slate-react";
import { FocusChangeRequest } from "./PowerEditorControlContext";
import { BaseRange, Editor, Node, Path, Element } from "slate";


export const nodePathToFirstLevelElementPath = (path: BaseRange): BaseRange => {
  const firstLevelPath = path.anchor.path.slice(0,1);
  return { anchor: { path: firstLevelPath, offset: 0}, focus: { path: firstLevelPath, offset: 0}};
}

export const getCurrentFirstLevelElement = (editor: ReactEditor): Node | null => {
  const element = editor.selection ? Editor.node(editor, nodePathToFirstLevelElementPath(editor.selection)) : null;
  if(element) {
    const [realElement] = element;
    return realElement || null;
  }

  return null;
}

export const getTargetForFocusRequest = (editor: ReactEditor, request: FocusChangeRequest): { node: Node | null, path: BaseRange | null } => {
  if(!editor) {
    return { node: null, path: null };
  }
  if(request.elementId) {
    const requestedId = request.elementId;
    const node = editor.children.find(n => (n as any)._id === requestedId || (n as any).id === requestedId);
    
    if(node) {
      const pathNode = ReactEditor.findPath(editor, node);
      if(pathNode) {
        const path = [...pathNode, 0];
        return { node, path: { anchor: { path, offset: 0 }, focus: { path, offset: 0 } } };
      }
    }
  }

  if(request.path && editor) {
    const path = request.path;
    const node = Editor.node(editor, nodePathToFirstLevelElementPath(path));
    if(node) {
      const [el] = node;
      return { node: el, path };
    } else {
      return { path, node: null };
    }
  }

  return { node: null, path: null };
}


export const arePathsSame = (a: Path, b: Path) => {
  return a.map(x => `${x}`).join(",") === b.map(x => `${x}`).join(",");
}

export const findCurrentElement = (editor: Editor) => {
  const [found] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n),
  });
  
  if(found) {
      return found[0] as Element;
  } else {
      return undefined;
  }
}

