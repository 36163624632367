
/*

\u00A0 - non-breaking space
\u200B - Zero-width space
\u2028 - line separator

*/

export const OffSpacesRegex = /[\u00A0\u200B]/g;


export const replaceTextNonBreakingSpaces = (text: string | undefined, weirdSpacesRegex = OffSpacesRegex) => {
  return text?.replace(weirdSpacesRegex, " ")
}

export const replaceContentNonBreakingSpaces = (content: any | undefined, weirdSpacesRegex = OffSpacesRegex) => {
  const blocks = content?.blocks?.map((b: any) => {
    if(b.children) {
      return {
        ...b,
        children: b.children?.map((c: any) => c.text
          ? { ...c, text: replaceTextNonBreakingSpaces(c.text, weirdSpacesRegex) }
          : c)
      } 
    } else {
      return b;
    }
    });

  return { ...content, blocks };
}
